import React from "react";
//import Preview from "../views/Preview";
// Homepage Demo
//import HomeDefault from "../views/all-home-version/HomeDefault";
//import HomeStudio from "../views/all-home-version/HomeStudio";
//import HomeAgency from "../views/all-home-version/HomeAgency";
//import HomeMinimal from "../views/all-home-version/HomeMinimal";
import HomeTrending from "../views/all-home-version/HomeTrending";
//import HomeFreelancer from "../views/all-home-version/HomeFreelancer";
//import HomeDark from "../views/all-home-version/HomeDark";
//import HomeModern from "../views/all-home-version/HomeModern";

// Service
import Service from "../views/inner-pages/service/Service";
import ServiceDetails from "../views/inner-pages/service/ServiceDetails";

import ITConsulting from "../views/inner-pages/service/it-consulting";
import WebDevelopment from "../views/inner-pages/service/web-development";
import DigitalMarketing from "../views/inner-pages/service/digital-marketing";
import AIDevelopment from "../views/inner-pages/service/ai-ml-development";
//import AppMarketing from "../views/inner-pages/service/app-marketing";



// About
import AboutUs from "../views/inner-pages/about/AboutUs";
//import AboutMe from "../views/inner-pages/about/AboutMe";

// Blog
//import BlogGrid from "../views/inner-pages/blog/BlogGrid";
//import BlogMasonry from "../views/inner-pages/blog/BlogMasonry";
//import BlogSidebar from "../views/inner-pages/blog/BlogSidebar";
//import BlogDetails from "../views/inner-pages/blog/BlogDetails";
//import BlogDetailsSidebar from "../views/inner-pages/blog/BlogDetailsSidebar";

// Portfolio
//import WorksGrid from "../views/inner-pages/portfolio/WorksGrid";
import WorksMasonry from "../views/inner-pages/portfolio/WorksMasonry";
//import WorksListing from "../views/inner-pages/portfolio/WorksListing";
//import WorksCarousel from "../views/inner-pages/portfolio/WorksCarousel";
//import WorksShowcase from "../views/inner-pages/portfolio/WorksShowcase";

// Case Studies
import ABHomecare from "../views/inner-pages/portfolio/ABHomecare";
import CareGiversAmerica from "../views/inner-pages/portfolio/CareGiversAmerica";
import Modivcare from "../views/inner-pages/portfolio/Modivcare";
import Evvsystems from "../views/inner-pages/portfolio/EvvSystems";
import AllMetro from "../views/inner-pages/portfolio/allmetro";
import CareFindersTotalCare from "../views/inner-pages/portfolio/carefinderstotalcare";
import FYSH from "../views/inner-pages/portfolio/fysh";
import GeriSafe from "../views/inner-pages/portfolio/gerisafe";
import IAP from "../views/inner-pages/portfolio/iap";
import SouthWest from "../views/inner-pages/portfolio/southwest";
import ServingSeniors from "../views/inner-pages/portfolio/servingseniors";
import StatelyPetSupply from "../views/inner-pages/portfolio/stately-pet-supply";
import FreedomHomeCare from "../views/inner-pages/portfolio/freedomhomecare";
import PanhandleSupportServices from "../views/inner-pages/portfolio/panhandlesupportservices";
import IndependenceHealthcareCorp from "../views/inner-pages/portfolio/independencehealthcarecorp";


// Others
import Contact from "../views/inner-pages/Contact";
import Termsofuse from "../views/inner-pages/Termsofuse";
import Privacypolicy from "../views/inner-pages/Privacypolicy";
//import PricingInner from "../views/inner-pages/PricingInner";
//import Team from "../views/inner-pages/Team";
//import ComingSoon from "../views/inner-pages/ComingSoon";
//import Faq from "../views/inner-pages/Faq";
import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        <Route path="/" element={<HomeTrending />} />
        {/*  Homepage Demo */}
        {/*  <Route path="/home-default" element={<HomeDefault />} /> */}
        {/*  <Route path="/home-studio" element={<HomeStudio />} /> */}
        {/*  <Route path="/home-agency" element={<HomeAgency />} /> */}
        {/*  <Route path="/home-minimal" element={<HomeMinimal />} /> */}
        {/*  <Route path="/home-trending" element={<HomeTrending />} /> */}
        {/*  <Route path="/home-freelancer" element={<HomeFreelancer />} /> */}
        {/*  <Route path="/home-dark" element={<HomeDark />} /> */}
        {/*  <Route path="/home-modern" element={<HomeModern />} /> */}

        {/* Service */}
        <Route path="/services" element={<Service />} />
        <Route path="/services/it-consulting" element={<ITConsulting />} />
        <Route path="/services/web-development" element={<WebDevelopment />} />
        <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
        <Route path="/services/ai-ml-development" element={<AIDevelopment />} />
        <Route path="/services/service-details" element={<ServiceDetails />} />

        {/* About */}
        <Route path="/about-us" element={<AboutUs />} />
        {/* <Route path="/about-me" element={<AboutMe />} /> */}

        {/* Blog */}
        {/* <Route path="/blog-grid" element={<BlogGrid />} /> */}
        {/* <Route path="/blog-masonry" element={<BlogMasonry />} /> */}
        {/* <Route path="/blog" element={<BlogSidebar />} /> */}
        {/* <Route path="/blog-details" element={<BlogDetails />} /> */}
        {/* <Route path="/blog-details-sidebar" element={<BlogDetailsSidebar />} /> */}

        {/* Portfolio */}
        {/* <Route path="/works-grid" element={<WorksGrid />} /> */}
        <Route path="/case-studies" element={<WorksMasonry />} />
        {/* <Route path="/works-listing" element={<WorksListing />} /> */}
        {/* <Route path="/works-carousel" element={<WorksCarousel />} /> */}
        {/* <Route path="/works-showcase" element={<WorksShowcase />} /> */}
        <Route path="/case-studies/abhomecare" element={<ABHomecare />} />
        <Route path="/case-studies/cga" element={<CareGiversAmerica />} />
        <Route path="/case-studies/modivcare" element={<Modivcare />} />
        <Route path="/case-studies/evvsystems" element={<Evvsystems />} />
        <Route path="/case-studies/allmetro" element={<AllMetro />} />
        <Route path="/case-studies/carefinderstotalcare" element={<CareFindersTotalCare />} />
        <Route path="/case-studies/fysh" element={<FYSH />} />
        <Route path="/case-studies/gerisafe" element={<GeriSafe />} />
        <Route path="/case-studies/iap" element={<IAP />} />
        <Route path="/case-studies/southwest" element={<SouthWest />} />
        <Route path="/case-studies/servingseniors" element={<ServingSeniors />} />
        <Route path="/case-studies/stately-pet-supply" element={<StatelyPetSupply />} />
        <Route path="/case-studies/freedomhomecare" element={<FreedomHomeCare />} />
        <Route path="/case-studies/panhandlesupportservices" element={<PanhandleSupportServices />} />
        <Route path="/case-studies/independencehealthcarecorp" element={<IndependenceHealthcareCorp />} />

        {/* others */}
        <Route path="/terms-of-use" element={<Termsofuse />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        {/* <Route path="/team" element={<Team />} /> */}
        {/* <Route path="/pricing" element={<PricingInner />} /> */}
        <Route path="/contact-us" element={<Contact />} />
        {/* <Route path="/coming-soon" element={<ComingSoon />} /> */}
        {/* <Route path="/faq" element={<Faq />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
