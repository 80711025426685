import React from "react";
import { Helmet } from "react-helmet";
import HeaderTrending from "../../../components/header/HeaderTrending";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import ServiceListThree from "../../../components/list/ServiceListThree";
import CallToAction from "../../../components/CallToAction";
import ApproachWD from "../../../components/service/Approach-wd";
import ImageGridFourWD from "../../../components/image-grid/ImageGridFour-WD";

const AIDevelopment = () => {
  return (
    <div>
      <Helmet>
        <title>Drive Success with CoveTech Digital Marketing</title>
        <meta name="description" content="Boost your online presence and traffic with CoveTech’s strategic digital marketing and SEO services." />
        <meta property="og:url" content="https://covetech.net/services/digital-marketing/" />
        <link rel="canonical" href="https://covetech.net/services/digital-marketing/" />
        <meta property="og:site_name" content="Drive Success with CoveTech Digital Marketing" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />

        <meta property="og:title" content="Drive Success with CoveTech Digital Marketing" />
        <meta property="og:description" content="Boost your online presence and traffic with CoveTech’s strategic digital marketing and SEO services." />
        <meta property="og:url" content="https://covetech.net/services/digital-marketing/" />
        <meta property="og:site_name" content="Drive Success with CoveTech Digital Marketing" />
        <meta property="og:image" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta property="og:image:secure_url" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Drive Success with CoveTech Digital Marketing" />
        <meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@CoveTechnology" />
        <meta name="twitter:creator" content="@CoveTechnology" />
        <meta name="twitter:title" content="Drive Success with CoveTech Digital Marketing" />
        <meta name="twitter:description" content="Boost your online presence and traffic with CoveTech’s strategic digital marketing and SEO services." />
        <meta name="twitter:image" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta name="twitter:image:alt" content="Drive Success with CoveTech Digital Marketing" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="twitter:image:src" content="https://covetech.net/assets/img/root/600PX.png" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTrending />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">Website Design & Development</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="mt-10">
                      We understand that your website is the cornerstone of your digital strategy. That's why we offer a suite of services designed to cover every aspect of website creation and maintenance, from initial design to long-term hosting and optimization. Our team of experts collaborates closely with you to understand your goals and deliver solutions that not only meet but exceed your expectations.</p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  <ImageGridFourWD />
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Website Design:</h3>
                    <p className="mt-10">Our design team specializes in creating bespoke websites that reflect your brand's identity and engage your audience. With a focus on aesthetics, functionality, and user experience, we ensure your online presence is powerful and memorable.</p>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Website Development:</h3>
                    <p className="mt-10">Leveraging cutting-edge technologies, we develop scalable, efficient, and secure websites. Our development approach is tailored to meet the unique requirements of your business, ensuring your website not only looks great but performs seamlessly.</p>


                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Web Hosting Solutions:</h3>
                    <p className="mt-10">Experience unmatched website performance and reliability with our web hosting services. We ensure your site remains accessible and fast, providing a superior user experience and bolstering your site’s SEO and retention rates.</p>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>AWS, Azure, Digital Ocean & Google Cloud Hosting:</h3>
                    <p className="mt-10">Capitalize on the scalability and flexibility of the cloud with our AWS and Google Cloud hosting services. Our team will help you navigate these powerful platforms, offering solutions that reduce costs, increase performance, and ensure your website scales effortlessly with your business.</p>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Containerization & Kubernetes:</h3>
                    <p className="mt-10">Embrace the future of web application deployment with our containerization and Kubernetes services. This modern approach allows for more efficient development cycles, scalable deployments, and high availability, ensuring your website remains robust and responsive under any load.</p>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Our Approach</h3>

                    <p className="mt-10">We've streamlined the path to creating your perfect digital presence into a clear, efficient four-step process. Here's how we transform your ideas into a dynamic online reality:</p>
                  </div>

                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "5.625rem" }}
                  ></div>
                  {/* <!--Process Steps--> */}
                  <ApproachWD />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-services-list">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">Services List</h4>
                        <ServiceListThree />
                      </div>
                    </div>
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <CallToAction />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AIDevelopment;
