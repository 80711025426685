import React from "react";
import { Helmet } from "react-helmet";
import HeaderTrending from "../../../components/header/HeaderTrending";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import PortfolioThree from "../../../components/portfolio/PortfolioThree";
{ /* import PortfolioSevenMasonry from "../../../components/portfolio/PortfolioSevenMasonry"; */ }


const WorksMasonry = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--works-masonry">
      <Helmet>
        <title>CoveTech Case Studies: Success Stories & Client Wins</title>
        <meta name="description" content="Dive into CoveTech's case studies to see our tech solutions in action. Discover how we drive success for clients across industries." />
        <meta property="og:url" content="https://covetech.net/case-studies/" />
        <link rel="canonical" href="https://covetech.net/case-studies/" />
        <meta property="og:site_name" content="CoveTech Case Studies: Success Stories & Client Wins" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />

        <meta property="og:title" content="CoveTech Case Studies: Success Stories & Client Wins" />
        <meta property="og:description" content="Dive into CoveTech's case studies to see our tech solutions in action. Discover how we drive success for clients across industries." />
        <meta property="og:url" content="https://covetech.net/case-studies/" />
        <meta property="og:site_name" content="CoveTech Case Studies: Success Stories & Client Wins" />
        <meta property="og:image" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta property="og:image:secure_url" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="CoveTech Case Studies: Success Stories & Client Wins" />
        <meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@CoveTechnology" />
        <meta name="twitter:creator" content="@CoveTechnology" />
        <meta name="twitter:title" content="CoveTech Case Studies: Success Stories & Client Wins" />
        <meta name="twitter:description" content="Dive into CoveTech's case studies to see our tech solutions in action. Discover how we drive success for clients across industries." />
        <meta name="twitter:image" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta name="twitter:image:alt" content="CoveTech Case Studies: Success Stories & Client Wins" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="twitter:image:src" content="https://covetech.net/assets/img/root/600PX.png" />
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderTrending />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <div className="ptf-page ptf-page--portfolio-masonry">
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>

              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-10">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="large-heading">
                        Design. Develop. Market.
                      </h1>
                    </div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "4.375rem" }}
                ></div>
              </div>
              {/* End .container-xxl */}
            </section>

            {/*=============================================
            Start Portfolio More 
            ============================================== */}
            <section>
              <div className="container-xxl">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <div
                    className="ptf-isotope-grid row"
                    style={{
                      "--bs-gutter-x": "4rem",
                      "--bs-gutter-y": "5.75rem",
                    }}
                  >
                    {/* <PortfolioSevenMasonry /> */}
                    <PortfolioThree />
                  </div>
                </div>
              </div>

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </section>
          </div>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default WorksMasonry;
