import React from "react";

const awardContent = [
  {
    date: "2019",
    img: "US20190027245A1",
    delayAnimation: "0",
    awardList: [
      {
        title: "20190027245",
        subTitle: "Electronic Visit Verification System",
        link: "https://patents.google.com/patent/US20190027245A1/en"
      },
    ],
  },
  {
    date: "2019",
    img: "US20190326009A2",
    delayAnimation: "100",
    awardList: [
      {
        title: "20190326009",
        subTitle: "EVV System and Method for Verifying a Visit with GPS",
        link: "https://patents.google.com/patent/US20190326009A1/en"
      },
    ],
  },
  {
    date: "2020",
    img: "US20200160987A1",
    delayAnimation: "US20200160987A1",
    awardList: [
      {
        title: "20200160987",
        subTitle: "EVV System and Method for Verifying a Visit with Smart TVs, Phones, VR and Auto Devices",
        link: "https://patents.google.com/patent/US20200160987A1/en"
      },
    ],
  },
];

const Award = () => {
  return (
    <ul
      className="ptf-rewards-list ptf-rewards-list--small"
      style={{
        "--ptf-border-width": "2px",
        "--ptf-border-color": "var(--ptf-color-black)",
      }}
    >
      {awardContent.map((val, i) => (
        <li
          className="ptf-rewards-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <div className="ptf-rewards-item__date">{val.date}</div>
          <div className="ptf-rewards-item__logo">
            <a href={val.link}>
              <img
                src={`/assets/img/root/rewards/${val.img}.png`}
                alt={val.title}
                loading="lazy"
              />
            </a>
          </div>
          {/* End .ptf-rewards-item__logo */}

          < div className="ptf-rewards-item__content" >
            {
              val.awardList.map((list, i) => (
                <div className="ptf-rewards-project" key={i}>
                  <div className="ptf-rewards-project__content ml-4">
                    <h5>
                      <a href={list.link}>{list.title}</a>
                    </h5>
                    <span>{list.subTitle}</span>
                  </div>
                  <div className="ptf-rewards-project__link">
                    <a
                      className="ptf-link-with-arrow text-uppercase fz-14 fw-500"
                      href={list.link}
                      target="_blank"
                    >
                      See Patent
                    </a>
                  </div>
                </div>
              ))
            }
            {/* End .ptf-rewards-project */}
          </div>
        </li >
      ))}
    </ul >
  );
};

export default Award;
