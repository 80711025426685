import React from "react";

const approachContent = [
  {
    title: "Discover & Plan",
    descriptions: `Your journey begins with a detailed discussion where we explore your vision, goals, and requirements for the project. Understanding your business and its unique needs is our top priority. Armed with insights from our initial meeting, we craft a customized strategy that outlines the project scope, timeline, and milestones. This plan serves as the roadmap to guide your project from concept to completion.`,
    delayAnimation: "0",
  },
  {
    title: "Design & Develop",
    descriptions: `Our design team gets to work, creating a bespoke website design that encapsulates your brand identity and appeals to your target audience. We focus on usability, aesthetics, and conversion optimization. With the design approved, our developers bring the vision to life using the latest web technologies. This phase involves building out the site’s structure, functionalities, and ensuring it’s responsive across all devices.`,
    delayAnimation: "100",
  },
  {
    title: "Deploy & Optimize",
    descriptions: `We select the ideal hosting solution for your needs, whether it’s on reliable web servers or scalable cloud platforms like AWS or Google Cloud. Our team ensures your site is secure, fast, and ready for traffic. Before launch, we rigorously test your website across browsers and devices for performance, security, and user experience. We also implement SEO best practices to ensure your site is discoverable and ranks well on search engines.`,
    delayAnimation: "200",
  },
  {
    title: "Launch & Grow",
    descriptions: `With everything in place, your website is ready to go live. We handle the launch process, ensuring a smooth transition and minimal downtime. Post-launch, we monitor your site's performance and gather user feedback for continuous improvement. Our team remains at your service for updates, optimizations, and scaling your site as your business grows.`,
    delayAnimation: "300",
  },
];

const ApproachWD = () => {
  return (
    <ul className="ptf-process-steps">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default ApproachWD;
