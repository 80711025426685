import React from "react";

const approachContent = [
  {
    subTitle: "Step One:",
    title: "Choosing the Right Team",
    descriptions: `We start by pulling together the perfect mix of minds - from business gurus to tech wizards and creative geniuses - all focused on what you want to achieve. Think of it as assembling your dream team for the win.`,
    delayAnimation: "0",
  },
  {
    subTitle: "Step Two:",
    title: "Brainstorming and Deep Dives",
    descriptions: `Next, we get all hands on deck to brainstorm and chat with you. It's all about uncovering those killer ideas and getting down to the brass tacks of what your business really needs. This way, we make sure we’re hitting the mark right from the start.`,
    delayAnimation: "100",
  },
  {
    subTitle: "Step Three:",
    title: "Crafting the Game Plan",
    descriptions: `Here's where we blend some smart design thinking with agile moves to sketch out a game plan that’s as flexible as it is spot-on. It’s about making sure we can pivot as we learn what works best for your business, keeping you in the loop every step of the way.`,
    delayAnimation: "200",
  },
  {
    subTitle: "Step Four:",
    title: "Laying Out the Blueprint",
    descriptions: `With the big picture in clear view, we'll lay out a detailed blueprint. We're talking about a clear list of features, a budget that makes sense, and a timeline that works. This step is all about ensuring you know exactly what you’re getting, how much it’ll cost, and when it’ll be ready.`,
    delayAnimation: "300",
  },
  {
    subTitle: "Step Five:",
    title: "Bringing Ideas to Life",
    descriptions: `Now, the fun really begins. Our team dives into bringing those plans to life, armed with all the skills in digital marketing, IT consulting, cloud solutions, and software development. We’re focused on crafting solutions that aren’t just effective but also make your business stand out.`,
    delayAnimation: "400",
  },
  {
    subTitle: "Step Six:",
    title: "Keeping You in the Lead",
    descriptions: ` Even after we launch, we’re not going anywhere. We stick around to keep everything running smoothly, making sure your project stays up-to-date and continues to thrive. It's about giving you peace of mind and keeping your business ahead of the game.`,
    delayAnimation: "500",
  },
];

const Approach = () => {
  return (
    <div
      className="row"
      style={{
        "--bs-gutter-x": "3.75rem",
        "--bs-gutter-y": "7.5rem",
      }}
    >
      {approachContent.map((val, i) => (
        <div className="col-xl-4 col-lg-6" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={val.delayAnimation}
          >
            {/* <!--Approach Box--> */}
            <div
              className="ptf-approach-box"
              style={{
                "--ptf-title-color": "var(--ptf-color-white)",
              }}
            >
              <div className="ptf-approach-box__subtitle">{val.subTitle}</div>
              <h3 className="ptf-approach-box__title h1">{val.title}</h3>
              <div className="ptf-approach-box__content">
                <p>{val.descriptions}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Approach;
