import React from "react";
import { Helmet } from "react-helmet";
import HeaderTrending from "../../../components/header/HeaderTrending";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import ServiceListThree from "../../../components/list/ServiceListThree";
import CallToAction from "../../../components/CallToAction";
import ApproachDM from "../../../components/service/Approach-dm";
import ImageGridFourDM from "../../../components/image-grid/ImageGridFour-DM";

const AIDevelopment = () => {
  return (
    <div>
      <Helmet>
        <title>Drive Success with CoveTech Digital Marketing</title>
        <meta name="description" content="Boost your online presence and traffic with CoveTech’s strategic digital marketing and SEO services." />
        <meta property="og:url" content="https://covetech.net/services/digital-marketing/" />
        <link rel="canonical" href="https://covetech.net/services/digital-marketing/" />
        <meta property="og:site_name" content="Drive Success with CoveTech Digital Marketing" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />

        <meta property="og:title" content="Drive Success with CoveTech Digital Marketing" />
        <meta property="og:description" content="Boost your online presence and traffic with CoveTech’s strategic digital marketing and SEO services." />
        <meta property="og:url" content="https://covetech.net/services/digital-marketing/" />
        <meta property="og:site_name" content="Drive Success with CoveTech Digital Marketing" />
        <meta property="og:image" content="https://covetech.net/assets/img/services/Digital-Marketing-Services.gif" />
        <meta property="og:image:secure_url" content="https://covetech.net/assets/img/services/Digital-Marketing-Services.gif" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Drive Success with CoveTech Digital Marketing" />
        <meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@CoveTechnology" />
        <meta name="twitter:creator" content="@CoveTechnology" />
        <meta name="twitter:title" content="Drive Success with CoveTech Digital Marketing" />
        <meta name="twitter:description" content="Boost your online presence and traffic with CoveTech’s strategic digital marketing and SEO services." />
        <meta name="twitter:image" content="https://covetech.net/assets/img/services/Digital-Marketing-Services.gif" />
        <meta name="twitter:image:alt" content="Drive Success with CoveTech Digital Marketing" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="twitter:image:src" content="https://covetech.net/assets/img/services/Digital-Marketing-Services.gif" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTrending />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">Digital Marketing</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="mt-10">
                      In today's digital-first world, establishing a strong online presence is not just an option—it's a necessity. Our full-service digital agency specializes in Website Design & Development, App Development, Digital Marketing, App Marketing, and Search Engine Optimization (SEO) to ensure your business not only competes but thrives in the digital marketplace.</p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  <ImageGridFourDM />
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Website Design & Development:</h3>
                    <p className="mt-10">Your website is often the first point of contact between your business and potential customers. We blend aesthetic appeal with functionality, creating responsive, user-friendly websites tailored to meet your business goals. From e-commerce platforms to informational sites, our team ensures your website reflects your brand, engages your audience, and drives conversions.</p>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Digital Marketing:</h3>
                    <p className="mt-10">Digital marketing is the engine that drives online visibility and customer engagement. Our comprehensive digital marketing strategies encompass social media, email marketing, content marketing, and more to build your brand, attract new customers, and retain existing ones. We focus on creating targeted campaigns that resonate with your audience and align with your business objectives.</p>


                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>App Marketing:</h3>
                    <p className="mt-10">Getting your app noticed in a crowded marketplace requires a strategic approach. Our app marketing services are designed to increase your app's visibility, downloads, and user engagement. Through app store optimization (ASO), targeted advertising, and social media campaigns, we ensure your app stands out and reaches its target audience.</p>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Search Engine Optimization (SEO):</h3>
                    <p className="mt-10">SEO is critical to increasing your website's visibility and attracting organic traffic. Our SEO experts implement the latest strategies and techniques to improve your website's ranking on search engines like Google. By optimizing your site's content, structure, and on-page elements, we help you attract more visitors and convert them into customers.</p>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>

                    <h3>Our Approach</h3>

                    <p className="mt-10">As software developers, inventors, and technology strategists, we pride ourselves on our creative and forward-thinking approach. We do not engage in hardware installations or networking; our focus is on delivering high-value software solutions and strategic consultancy that drive digital innovation and business growth.</p>
                  </div>

                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "5.625rem" }}
                  ></div>
                  {/* <!--Process Steps--> */}
                  <ApproachDM />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-services-list">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">Services List</h4>
                        <ServiceListThree />
                      </div>
                    </div>
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <CallToAction />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AIDevelopment;
