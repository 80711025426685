import React from "react";
import { Link } from "react-router-dom";
import DynamicSEO from '../../components/seo/DynamicSEO';
import HeaderTrending from "../../components/header/HeaderTrending";
import FooterThree from "../../components/footer/FooterThree";
import CopyRightThree from "../../components/footer/copyright/CopyRightThree";
import PortfolioThree from "../../components/portfolio/PortfolioThree";
import ServiceTwo from "../../components/service/ServiceTwo";
import BrandTwo from "../../components/brand/BrandTwo";

const HomeTrending = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--home-trending ">
      <DynamicSEO
        title="CoveTech: Leading the Way in Digital Innovation & Tech Solutions"
        description="Discover CoveTech's expertise in AI, digital transformation, and tech consulting to revolutionize your business."
        url="https://covetech.net/"
        siteName="CoveTech"
        fbAppId="1093442508572315"
        twitterUsername="@CoveTechnology"
        imageUrl="https://covetech.net/assets/img/about/cover.png"
        schemaType="Organization"
        schemaData={{
          name: "CoveTech",
          url: "https://covetech.net/",
          logo: "https://covetech.net/assets/img/logo.png",
          sameAs: [
            "https://www.facebook.com/CoveTech",
            "https://twitter.com/CoveTechnology",
            "https://www.linkedin.com/company/covetech/",
          ],
          // Additional schema data here
        }}
      />
      {/* End Page SEO Content */}

      <HeaderTrending />
      {/* End  HeaderTrending */}

      <div className="ptf-site-wrapper__inner">
        <div className="ptf-main">
          <div className="ptf-page ptf-page--home-trending">
            {/*=============================================
            Start Hero Section 
            ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}
              ></div>

              <div className="container">
                {/* {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h1 className="fz-130 fz-90--md has-secondary-font fw-normal">
                    We transform businesses through the use of
                    <span className="has-accent-1"> AI </span> &
                    <span className="has-accent-1"> Emerging</span> technologies
                  </h1>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                <div className="row">
                  <div className="col-xl-7 d-none d-xl-block">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": " 5rem" }}
                    ></div>
                    {/* {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="200"
                    >
                      {/* <!--Mask Image--> */}
                      <div
                        className="ptf-mask-image mx-auto"
                        style={{ maxWidth: "18.25rem" }}
                      >
                        <img
                          className="spin"
                          src="/assets/img/root/home-trending/vetowned.png"
                          alt="circle text"
                          loading="lazy"
                        />
                        <img
                          src="/assets/img/root/home-trending/vet.png"
                          alt="circle logo"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5">
                    {/* {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="100"
                    >
                      <p className="fz-30" style={{ paddingRight: "1rem" }}>
                        We are a team of passionate developers and patent consultants who
                        create products that help businesses grow, evolve, & transform.
                        <br />
                        <br />
                        <strong>Design. <span className="has-accent-1">Develop.</span> Deploy. <span className="has-accent-1">Market.</span></strong>

                      </p>
                    </div>
                  </div>
                </div>
                {/* End .row */}
              </div>
              {/* End .container */}

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </section>

            {/*=============================================
            Start Service Section 
            ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}
              ></div>

              <div className="container">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2 className="h1 large-heading has-secondary-font fw-normal">
                    Our Services
                  </h2>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.875rem" }}
                ></div>
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="100"
                >
                  <p className="fz-24" style={{ maxWidth: "42.5rem" }}>
                    We help ambitious companies and agencies generate more business,
                    increase profits and long term valuation by creating new verticals,
                    products, services & revenue streams.
                  </p>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>
                <div className="row" style={{ "--bs-gutter-y": "3.75rem" }}>
                  <ServiceTwo />
                </div>
              </div>

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": " 5rem" }}
              ></div>
            </section>

            <section>
              <div className="container">
                {/* <!--Divider--> */}
                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-color": "var(--ptf-color-14)",
                    "--ptf-height": "1px",
                  }}
                ></div>
              </div>
            </section>
            {/* End section devider */}

            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}
              ></div>
              <div className="container">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2 className="h1 large-heading has-secondary-font fw-normal">
                    Our Clients
                  </h2>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "7.5rem", "--ptf-md": "3.75rem" }}
                ></div>
                {/* <!--Clients List--> */}
                <BrandTwo />
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </section>

            <section>
              <div className="container">
                {/* <!--Divider--> */}
                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-color": "var(--ptf-color-14)",
                    "--ptf-height": "1px",
                  }}
                ></div>
              </div>
            </section>
            {/* End section devider */}
            <section>
              <div className="container-xxl">
                {/* <!--Divider--> */}
                <div
                  className="ptf-dividers"
                  style={{
                    "--ptf-color": "var(--ptf-color-14)",
                    "--ptf-height": "1px",
                  }}
                ></div>
              </div>
            </section>
            {/* End section devider */}
          </div>
          {/* End .ptf-page */}
        </div>
        {/* End .ptf-main */}
      </div >
      {/* End .ptf-site-wrapper__inner */}


      {/*=============================================
            Start Latest Project Section 
            ============================================== */}
      <section>
        {/* <!--Spacer--> */}
        <div
          className="ptf-spacer"
          style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}
        ></div>

        <div className="container">
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="0"
          >
            <h2 className="h1 large-heading has-secondary-font fw-normal">
              Latest Projects
            </h2>
          </div>
          {/* <!--Spacer--> */}
          <div
            className="ptf-spacer"
            style={{ "--ptf-xxl": "1.875rem" }}
          ></div>
          {/* {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="100"
          >
            <p className="fz-24" style={{ maxWidth: "16.875rem" }}>
              Growth is our passion.
            </p>
          </div>
          {/* <!--Spacer--> */}
          <div
            className="ptf-spacer"
            style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
          ></div>
        </div>
        {/* End .container */}

        <div className="container-xxl">
          {/* {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="0"
          >
            <div
              className="ptf-isotope-grid row"
              style={{
                "--bs-gutter-x": "4.75rem",
                "--bs-gutter-y": "4.75rem",
              }}
            >
              <PortfolioThree />
            </div>
          </div>
          {/* End portfolio */}
          {/* <!--Spacer--> */}
          <div
            className="ptf-spacer"
            style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
          ></div>
          <div className="text-center">
            {/* {/* <!--Animated Block--> */}
            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay="0"
            >
              <Link
                className="ptf-circle-link ptf-circle-link--style-2"
                to="/case-studies"
              >
                All <br />
                Projects
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  style={{ height: "1em" }}
                  viewBox="0 0 17 17"
                >
                  <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                </svg>
              </Link>
            </div>
          </div>
          {/* <!--Spacer--> */}
          <div
            className="ptf-spacer"
            style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
          ></div>
        </div>
        {/* End .container-xxl */}
      </section>
      {/* End Portfolio Endpoint */}

      <section>
        <div className="container">
          {/* <!--Divider--> */}
          <div
            className="ptf-divider"
            style={{
              "--ptf-color": "var(--ptf-color-14)",
              "--ptf-height": "1px",
            }}
          ></div>
        </div>
      </section>
      {/* End section devider */}

      <section>
        {/* <!--Spacer--> */}
        <div
          className="ptf-spacer"
          style={{ "--ptf-xxl": "12.5rem", "--ptf-md": "6.25rem" }}
        ></div>
        <div className="container text-center">
          <div className="d-inline-flex text-start">
            {/* <!--Animated Block--> */}
            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay="0"
            >
              <div className="fz-30">Let's Talk</div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "1.25rem" }}
              ></div>
              <div
                className="
                        fz-130
                        fz-90--md
                        has-black-color has-secondary-font
                        fw-normal
                        lh-1p1
                      "
              >
                <Link className="ptf-filled-link" to="/contact-us">
                  Get Started
                </Link>
              </div>
              <div className="fz-80 has-black-color">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  style={{ height: "1em" }}
                  viewBox="0 0 17 17"
                >
                  <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* <!--Spacer--> */}
        <div
          className="ptf-spacer"
          style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
        ></div>
      </section>


      {/*=============================================
        Start Footer Section 
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-2">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          {/* End .ptf-footer__top */}

          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
          {/* End .ptf-footer__bottom */}
        </div>
      </footer>
    </div >
  );
};

export default HomeTrending;
