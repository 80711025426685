import React from "react";

const approachContent = [
  {
    title: "Identify Objectives",
    descriptions: `Start by identifying what you want to achieve with AI and ML in your business. This could range from enhancing customer service with chatbots, improving sales forecasts, automating repetitive tasks, or gaining deeper insights from data analytics. Clear objectives will guide the choice of technologies and approaches.`,
    delayAnimation: "0",
  },
  {
    title: "Assess Data Readiness",
    descriptions: `AI and ML algorithms require data to learn and make predictions. Assess the quality, quantity, and variety of your data. This step may involve data cleaning, structuring, and enrichment to ensure your AI and ML models can be effectively trained.`,
    delayAnimation: "100",
  },
  {
    title: "Develop and Integrate Solutions",
    descriptions: `Depending on your business's specific needs and capabilities, you can either develop custom AI and ML models or adapt existing solutions. This step involves selecting the right algorithms, training the models with your data, and integrating them into your business processes.`,
    delayAnimation: "200",
  },
  {
    title: "Monitor, Evaluate, and Iterate",
    descriptions: `After deploying AI and ML solutions, it's crucial to monitor their performance and impact. Collect feedback, measure results against your objectives, and look for areas of improvement. Continuous evaluation and iteration will help you refine the solutions to better meet your business needs.`,
    delayAnimation: "300",
  },
];

const ApproachAI = () => {
  return (
    <ul className="ptf-process-steps">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default ApproachAI;
