import React from "react";
import { Link } from "react-router-dom";

const serviceContent = [
  {
    delayAnimation: "0",
    title: "AI",
    serviceList: [
      "AI Chatbot Development",
      "Generative AI development",
      "Prompt Engineering",
      "Intelligent Virtual Assistants",
      "Computer Vision",
    ],
    url: "/services/ai-ml-development",
  },
  {
    delayAnimation: "100",
    title: "IT Consulting",
    serviceList: [
      "Digital Transformation",
      "Product Ideation & Development",
      "Technology Consulting",
      "Cloud Consulting",
      "IT Tech Audit Services",
    ],
    url: "/services/it-consulting",
  },
  {
    delayAnimation: "200",
    title: "Digital Marketing",
    serviceList: [
      "Website Design & Development",
      "Mobile App Development",
      "Mobile App Marketing",
      "Digital Marketing",
      "Search Engine Optimization",
    ],
    url: "/services/digital-marketing",
  },
];

const ServiceTwo = () => {
  return (
    <>
      {serviceContent.map((item, i) => (
        <div className="col-12 col-md-6 col-lg-4" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={item.delayAnimation}
          >
            <h6 className="fz-16 text-uppercase has-3-color fw-normal">

            </h6>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "2.8125rem" }}
            ></div>
            {/* <!--Services List--> */}
            <ul className="ptf-services-list ptf-services-list--style-2">
              {item.serviceList.map((val, i) => (
                <li key={i}>
                  <Link to={`${item.url}`}>{val}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServiceTwo;
