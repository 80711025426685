import React from "react";
import { Helmet } from "react-helmet";
import HeaderTrending from "../../components/header/HeaderTrending";
import CopyRight from "../../components/footer/copyright/CopyRight";
import Footer from "../../components/footer/Footer";
import Social from "../../components/social/Social";
import ContactForm from "../../components/ContactForm";
import Address from "../../components/Address";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Connect with CoveTech: Your Tech Solutions Partner</title>
        <meta name="description" content="Reach out to discuss how CoveTech’s innovative solutions can elevate your business to new heights." />
        <meta property="og:url" content="https://covetech.net/contact-us/" />
        <link rel="canonical" href="https://covetech.net/contact-us/" />
        <meta property="og:site_name" content="CoveTech: Leading Digital Innovation & Tech Solutions" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />

        <meta property="og:title" content="Connect with CoveTech: Your Tech Solutions Partner" />
        <meta property="og:description" content="Reach out to discuss how CoveTech’s innovative solutions can elevate your business to new heights." />
        <meta property="og:url" content="https://covetech.net/contact-us/" />
        <meta property="og:site_name" content="CoveTech: Leading Digital Innovation & Tech Solutions" />
        <meta property="og:image" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta property="og:image:secure_url" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Connect with CoveTech: Your Tech Solutions Partner" />
        <meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@CoveTechnology" />
        <meta name="twitter:creator" content="@CoveTechnology" />
        <meta name="twitter:title" content="Connect with CoveTech: Your Tech Solutions Partner" />
        <meta name="twitter:description" content="Reach out to discuss how CoveTech’s innovative solutions can elevate your business to new heights." />
        <meta name="twitter:image" content="https://covetech.net/assets/img/root/600PX.png" />
        <meta name="twitter:image:alt" content="Connect with CoveTech: Your Tech Solutions Partner" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="twitter:image:src" content="https://covetech.net/assets/img/root/600PX.png" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTrending />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">
                      Let's get Contact an <br />
                      estimate
                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3rem", "--ptf-md": "2.5rem" }}
                    ></div>

                    <Social />
                    {/* <!--Social Icon--> */}
                  </div>
                </div>
              </div>
              {/* End .row */}
            </div>
            {/* Contact top portion */}

            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End section contact header */}

          <section>
            <div className="container-xxl">
              <div className="row">
                <div className="col-lg-4">
                  <Address />
                </div>
                {/* End .col */}

                <div className="col-lg-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <h5 className="fz-14 text-uppercase has-3-color fw-normal">
                      Tell us about your project and goals.
                    </h5>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3.125rem" }}
                    ></div>
                    <ContactForm />
                    {/* End ContactForm */}
                  </div>
                </div>
                {/* End .col */}
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
