import React from "react";
import { Link } from "react-router-dom";

const menuContent = [
  {
    name: "Home",
    routerPath: "/",
    activeClass: "",
    menuClass: "",
  },
  {
    name: "About Us",
    activeClass: "",
    menuClass: "",
    routerPath: "/about-us",
  },
  {
    name: "Services",
    activeClass: "",
    menuClass: "",
    routerPath: "/services",
  },
  {
    name: "Case Studies",
    activeClass: "",
    menuClass: "",
    routerPath: "/case-studies",
  },
  {
    name: "Contact",
    activeClass: "",
    menuClass: "",
    routerPath: "/contact-us",
  }
];


const DropdownMenu = () => {
  return (
    <ul className="sf-menu">
      {menuContent.map((item, i) => (
        <li className={`menu-item-has-children ${item.menuClass}`} key={i}>
          <a href={item.routerPath} className={item.activeClass}>
            <span>{item.name}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default DropdownMenu;
