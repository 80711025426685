import React from 'react';
import { Helmet } from 'react-helmet';

const DynamicSEO = ({
    title,
    description,
    url,
    siteName,
    fbAppId,
    twitterUsername,
    imageUrl,
    schemaType,
    schemaData, // This should be an object formatted as JSON-LD
}) => (
    <Helmet>
        {/* Standard Tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />

        {/* Open Graph Tags */}
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content={fbAppId} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:secure_url" content={imageUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content={title} />
        <meta property="og:image:type" content="image/png" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={twitterUsername} />
        <meta name="twitter:creator" content={twitterUsername} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:image:alt" content={title} />

        {/* Schema.org Markup for Structured Data */}
        <script type="application/ld+json">
            {JSON.stringify({
                "@context": "http://schema.org",
                "@type": schemaType,
                ...schemaData,
            })}
        </script>
    </Helmet>
);

export default DynamicSEO;
