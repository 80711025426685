import React from "react";

const approachContent = [
  {
    title: "Discovery & Strategy",
    descriptions: `Begin your digital transformation journey with an in-depth consultation where we discuss your business goals, challenges, and digital needs. This initial discovery phase is crucial for understanding your vision and how our services can align with your objectives.  Following the consultation, we craft a customized digital strategy that outlines the services best suited to achieve your goals. Whether it's a new website, a mobile app, or a comprehensive SEO campaign, our plan will detail the approach, timelines, and milestones.`,
    delayAnimation: "0",
  },
  {
    title: "Design & Development",
    descriptions: `For website and app projects, our design team gets to work on creating visually appealing and user-friendly interfaces that reflect your brand identity and engage your target audience.  Our developers then bring these designs to life, ensuring robust functionality and seamless user experiences. For digital marketing projects, we develop content, set up campaigns, and prepare for launch.`,
    delayAnimation: "100",
  },
  {
    title: "Launch & Optimization",
    descriptions: `With everything in place, we move to launch your project. This could be the unveiling of your new website, the launch of your mobile app, or the kick-off of your digital marketing campaigns.  Post-launch, we monitor performance and gather data to optimize further and refine our strategies. For websites and apps, this means usability improvements and updates. For digital marketing, we tweak campaigns for better results.`,
    delayAnimation: "200",
  },
  {
    title: "Growth & Support",
    descriptions: `As your business grows, we're here to support and adapt our strategies accordingly. Whether you need additional features for your website/app, want to expand your digital marketing efforts, or need ongoing SEO support, we're committed to your long-term success.`,
    delayAnimation: "300",
  },
];

const ApproachAI = () => {
  return (
    <ul className="ptf-process-steps">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default ApproachAI;
