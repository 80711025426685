import React from "react";
import { Link } from "react-router-dom";

const portfolioContent = [
  {
    img: "modivcare-home",
    categorie: "Digital Marketing, Web Design, IT Consulting",
    title: "Modivicare, Inc.",
    url: "/case-studies/modivcare",
  },

  {
    img: "CGA-Website-home",
    categorie: "Digital Marketing, Web Design, IT Consulting",
    title: "CareGivers America",
    url: "/case-studies/cga",
  },
  {
    img: "AB-Homecare-home",
    categorie: "Web Design",
    title: "AB Homecare",
    url: "/case-studies/abhomecare",
  },
  {
    img: "southwest-home",
    categorie: "Web Design, Digital Marketing, Social Media Marketing",
    title: "Southwest Airlines",
    url: "/case-studies/southwest",
  },
  {
    img: "gerisafe-home",
    categorie: "IT Consulting, App Development, Prototype",
    title: "Geri-Safe's MediTrust Prototype",
    url: "/case-studies/gerisafe",
  },
  {
    img: "all-metro-home",
    categorie: "IT Consulting",
    title: "All Metro",
    url: "/case-studies/allmetro",
  },
  {
    img: "carefinderstotalcare-home",
    categorie: "Digital Marketing",
    title: "Carefinders Total Care",
    url: "/case-studies/carefinderstotalcare",
  },
  {
    img: "panhandle-support-services-home",
    categorie: "Digital Marketing, Web Design",
    title: "Panhandle Support Services",
    url: "/case-studies/panhandlesupportservices",
  },
  {
    img: "FreedomHomecare-home",
    categorie: "Digital Marketing, Web Design",
    title: "Freedom Homecare",
    url: "/case-studies/freedomhomecare",
  },
  {
    img: "IndependenceHealthCareCorp-home",
    categorie: "Digital Marketing, Web Design, IT Consulting",
    title: "Independence Healthcare Corp",
    url: "/case-studies/independencehealthcarecorp",
  },

];

const PortfolioThree = () => {
  return (
    <>
      {portfolioContent.map((val, i) => (
        <div className="col-lg-6" key={i}>
          <div className=" grid-item filter-1 filter-4 filter-7">
            {/* <!--Portfolio Item--> */}
            <article className="ptf-work ptf-work--style-6">
              <div className="ptf-work__media">
                <img
                  src={`/assets/img/home/studio/${val.img}.jpg`}
                  alt={val.title}
                  loading="lazy"
                />
              </div>
              <Link className="ptf-work__meta" to={`${val.url}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  style={{ height: "1em" }}
                  viewBox="0 0 17 17"
                >
                  <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                </svg>
                <div className="ptf-work__category">{val.categorie}</div>
                <h4 className="ptf-work__title">{val.title}</h4>
              </Link>
            </article>
          </div>
        </div>
      ))}
    </>
  );
};

export default PortfolioThree;
