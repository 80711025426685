import React from "react";
import { Link } from "react-router-dom";

const footerItems = [
  { itemName: "About Us", link: "/about-us" },
  { itemName: "Case Studies", link: "/case-studies" },
  { itemName: "Services", link: "/services" },
  { itemName: "Contact", link: "/contact-us" },
  { itemName: "Terms of Use", link: "/terms-of-use" },
  { itemName: "Privacy Policy", link: "/privacy-policy" },
];

const FooterList = () => {
  return (
    <ul>
      {footerItems.map((item, i) => (
        <li key={i}>
          <Link to={item.link}>{item.itemName}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterList;
