import React from "react";
import { Link } from "react-router-dom";

const serviceContent = [
  {
    icon: "lnil lnil-consulting",
    titlePart1: "IT",
    titlePart2: "CONSULTING",
    descriptions: ` Elevating your business to new heights and increasing your process efficiency with customized, results-driven consulting services. `,
    link: "/services/it-consulting",
  },
  {
    icon: "lnil lnil-website-rank",
    titlePart1: "WEB",
    titlePart2: "DEVELOPMENT",
    descriptions: ` We design websites for a wide range of industries including: Healthcare, Industrial, Logistics, Supply Chain, Small Business and Retail Industries.`,
    link: "/services/web-development",
  },
  {
    icon: "lnil lnil-presentation",
    titlePart1: "DIGITAL",
    titlePart2: "MARKETING",
    descriptions: ` In the field we are called Technical SEO Experts. Outside of the field we are called Marketers. Regardless of our names, we analyze and build businesses through the use of technology.`,
    link: "/services/digital-marketing",
  },
  {
    icon: "lnil lnil-ai",
    titlePart1: "AI & ML",
    titlePart2: "DEVLOPMENT",
    descriptions: ` You can implement dedicated ML solutions, or integrate tools like ChatGPT to optimize your company's costs, improve internal efficiency, and gain a competitive advantage.`,
    link: "/services/ai-ml-development",
  },
];

const ServiceOne = () => {
  return (
    <>
      {serviceContent.map((val, i) => (
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="0"
          key={i}
        >
          {/* <!--Services Box--> */}
          <div className="ptf-service-box">
            <Link
              to={val.link} className="ptf-service-box__link"></Link>
            <div className="ptf-service-box__icon">
              <i className={val.icon}></i>
            </div>
            <h5 className="ptf-service-box__title">
              {val.titlePart1} <br />
              {val.titlePart2}
            </h5>
            <div className="ptf-service-box__content">
              <p>{val.descriptions}</p>
            </div>
            <div className="ptf-service-box__arrow">
              <i className="lnil lnil-chevron-right"></i>
            </div>
          </div>
        </div >
      ))}
    </>
  );
};

export default ServiceOne;
