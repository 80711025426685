import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../components/header/HeaderDefault";
import CopyRight from "../../components/footer/copyright/CopyRight";
import Footer from "../../components/footer/Footer";

const Privacypolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy - CoveTech</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--Termsofuse">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">Privacy Policy</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>

                    <h4>Last Updated: 03/13/2024</h4>
                    <p className="fz-18">Welcome to Cove Technology, Inc. ("Cove Technology," "we," "us," or "our"). We respect your privacy and are committed
                      to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard
                      your information when you visit our website <a target="_new" href="https://covetech.net">https://covetech.net</a>
                      (the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy,
                      please do not access the Site.</p>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <h5>1. Information We Collect</h5>
                    <p className="fz-18">We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>
                    <ul>
                      <li className="fz-18 text-list"><strong>Personal Data</strong>: Personally identifiable information, such as your name, shipping address, email
                        address, and telephone number, and demographic information, such as your age, gender, hometown, and interests,
                        that you voluntarily give to us when you choose to participate in various activities related to the Site, such
                        as online chat and message boards.</li>
                      <li className="fz-18 text-list"><strong>Derivative Data</strong>: Information our servers automatically collect when you access the Site, such
                        as your IP address, browser type, operating system, access times, and the pages you have viewed directly before
                        and after accessing the Site.</li>
                      <li className="fz-18 text-list"><strong>Financial Data</strong>: Financial information, such as data related to your payment method (e.g., valid
                        credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange,
                        or request information about our services from the Site.</li>
                    </ul>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <h5>2. How We Use Your Information</h5>
                    <p className="fz-18">We use the information we collect about you to:</p>
                    <ul>
                      <li className="fz-18 text-list">Provide, operate, and maintain our Site</li>
                      <li className="fz-18 text-list">Improve, personalize, and expand our Site</li>
                      <li className="fz-18 text-list">Understand and analyze how you use our Site</li>
                      <li className="fz-18 text-list">Develop new products, services, features, and functionality</li>
                      <li className="fz-18 text-list">Communicate with you, either directly or through one of our partners, including for customer service, to provide
                        you with updates and other information relating to the Site, and for marketing and promotional purposes</li>
                      <li className="fz-18 text-list">Send you emails</li>
                      <li className="fz-18 text-list">Find and prevent fraud</li>
                    </ul>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <h5>3. How We Share Your Information</h5>
                    <p className="fz-18">We may share information we have collected about you in certain situations. Your information may be disclosed as
                      follows:</p>
                    <ul>
                      <li className="fz-18 text-list"><strong>Third-Party Service Providers</strong>: We may share your information with third parties that perform
                        services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services,
                        customer service, and marketing assistance.</li>
                      <li className="fz-18 text-list"><strong>Marketing Communications</strong>: With your consent, or with an opportunity for you to withdraw
                        consent, we may share your information with third parties for marketing purposes, as permitted by law.</li>
                      <li className="fz-18 text-list"><strong>Legal Obligations</strong>: We may share your information as required by law or subpoena or if we
                        believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or
                        to protect the security or integrity of our Site.</li>
                    </ul>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <h5>4. Security of Your Information</h5>
                    <p className="fz-18">We use administrative, technical, and physical security measures to help protect your personal information. While we
                      have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our
                      efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed
                      against any interception or other types of misuse.</p>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <h5>5. Contact Us</h5>
                    <p className="fz-18">If you have questions or comments about this Privacy Policy, please contact us at:</p>
                    <ul>
                      <li className="fz-18 no-bullet">Cove Technology, Inc.</li>
                      <li className="fz-18 no-bullet">Dalton, PA 18414</li>
                      <li className="fz-18 no-bullet">Email: <a target="_new" href="mailto:sales@covetech.net">sales@covetech.net</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Privacypolicy;
