import React from "react";

const approachContent = [
  {
    title: "Strategy and Planning",
    descriptions: `Kick off your journey by clearly defining your business objectives and technology goals. Whether you're looking to undergo a digital transformation, develop a new product, or enhance your technology infrastructure, our initial strategy sessions will align your vision with actionable plans.`,
    delayAnimation: "0",
  },
  {
    title: "Assessment and Analysis",
    descriptions: `We conduct thorough assessments and analyses of your existing technology landscape, including software, systems, and cloud infrastructure. This step is crucial for identifying opportunities for optimization, innovation, and growth. Our IT audit services play a key role here, providing insights into potential improvements and alignments with industry best practices.`,
    delayAnimation: "100",
  },
  {
    title: "Implementation and Development",
    descriptions: `Armed with a strategic plan and detailed analysis, we move into the implementation phase. This can range from initiating digital transformation projects, starting product development cycles, to deploying cloud solutions. Our team ensures that every technological solution is tailor-made to fit your unique needs, driving efficiency, scalability, and market relevance.`,
    delayAnimation: "200",
  },
  {
    title: "Review, Optimize, and Scale",
    descriptions: `Technology and markets evolve, and so should your business. Following the implementation, we review the outcomes against your objectives, gathering feedback and measuring performance. Based on these insights, we optimize the solutions and strategies in place, ensuring they continue to support your growth and adaptation in the digital landscape.`,
    delayAnimation: "300",
  },
];

const ApproachAI = () => {
  return (
    <ul className="ptf-process-steps">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default ApproachAI;
